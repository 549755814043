import * as React from "react";
import {
    TextInput,
    SimpleForm,
    useRedirect,
    useNotify,
    useRefresh,
    required,
    CreateProps,
    Create,
    SaveButton,
    Toolbar,
    SelectArrayInput,
} from "react-admin";
import {
    ManyToManyReferenceContextProvider,
    ReferenceManyToManyInput,
} from "ra-relationships";

const DeviceCreateToolbar = (props: any) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
};

export const DeviceCreate = (props: CreateProps) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = (response: any) => {
        notify(`Device ${response.data.friendlyName} saved!`);
        redirect("/devices");
        refresh();
    };
    return (
        <Create {...props} onSuccess={onSuccess}>
            <ManyToManyReferenceContextProvider>
                <SimpleForm toolbar={<DeviceCreateToolbar />}>
                    <TextInput source="friendlyName" validate={required()} />
                    <TextInput source="address" validate={required()} />
                    <TextInput source="city" validate={required()} />
                    <TextInput source="deviceName" validate={required()} />
                    <TextInput
                        label="Service UUID"
                        source="serviceUUID"
                        fullWidth
                        helperText="The Bluetooth Service UUID for communication"
                        validate={required()}
                    />
                    <TextInput
                        source="writeCharacteristic"
                        fullWidth
                        helperText="The Bluetooth Characteristic UUID for write communication"
                        validate={required()}
                    />
                    <TextInput
                        source="readCharacteristic"
                        fullWidth
                        helperText="The Bluetooth Characteristic UUID for read communication"
                        validate={required()}
                    />
                    <ReferenceManyToManyInput
                        source="id"
                        reference="users"
                        through="junction_user_device"
                        using="deviceUid,userUid"
                        fullWidth
                        label="Users"
                    >
                        <SelectArrayInput
                            optionText={(choice: any) => {
                                return `${choice.name} - ${choice.email} - ${choice.phoneNumber}`;
                            }}
                            helperText="Users assigned to current device"
                        />
                    </ReferenceManyToManyInput>
                </SimpleForm>
            </ManyToManyReferenceContextProvider>
        </Create>
    );
};
