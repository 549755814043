import { ManyToManyReferenceContextProvider } from "ra-relationships";
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    ListProps,
    ShowButton,
    EditButton,
} from "react-admin";
import DevicesBulkDeleteWithConfirmationButton from "./DevicesBulkDeleteWithConfirmationButton";

const DeviceBulkActionButtons = (props: any) => (
    <>
        <DevicesBulkDeleteWithConfirmationButton {...props} />
    </>
);

const DeviceFilter = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput label="Name" source="friendlyName" alwaysOn />
            <TextInput label="Address" source="address" />
            <TextInput label="City" source="city" />
        </Filter>
    );
};

export const DevicesList = (props: ListProps) => {
    return (
        <ManyToManyReferenceContextProvider>
            <List
                {...props}
                filters={<DeviceFilter />}
                bulkActionButtons={<DeviceBulkActionButtons />}
                perPage={25}
            >
                <Datagrid rowClick="edit">
                    <TextField source="friendlyName" />
                    <TextField source="address" />
                    <TextField source="city" />
                    <ShowButton />
                    <EditButton basePath="/devices" />
                </Datagrid>
            </List>
        </ManyToManyReferenceContextProvider>
    );
};
