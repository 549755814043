import React from "react";
import { Login, LoginForm } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

// eslint-disable-next-line unused-imports/no-unused-vars-ts
const useStyles = makeStyles((theme) => ({
    avatar: {
        background:
            "url(https://static.wixstatic.com/media/c3f193_0b722a94c38c46698b62bf97e0d20959.png/v1/fill/w_202,h_82,al_c,q_85,usm_0.66_1.00_0.01/c3f193_0b722a94c38c46698b62bf97e0d20959.webp)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: 80,
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 43,
    },
    card: {
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 20,
    },
    main: {
        background: "#01305b",
    },
    icon: { display: "none" },
}));

const CustomLoginForm = (props: any) => {
    return (
        <div>
            <LoginForm {...props} />
        </div>
    );
};

const CustomLoginPage = (props: any) => {
    const classes = useStyles(props);
    return (
        <Login {...props} classes={classes}>
            <Typography align="center">Admin Dashboard</Typography>
            <CustomLoginForm {...props} />
        </Login>
    );
};

export default CustomLoginPage;
