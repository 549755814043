import * as React from "react";
import {
    Datagrid,
    TextField,
    Show,
    SimpleShowLayout,
    ShowProps,
} from "react-admin";
import { ReferenceManyToManyField } from "ra-relationships";
import { makeStyles } from "@material-ui/core";

const DeviceShowTitle = ({ record }: any) => {
    return <span>View Device: {record ? `${record.friendlyName}` : ""}</span>;
};

const deviceRowStyle = (record: any, index: any) => ({
    backgroundColor: index % 2 === 0 ? "white" : "white",
});

const useStyles = makeStyles({
    header: {
        fontWeight: "bold",
    },
});

export const DeviceShow = (props: ShowProps) => {
    const classes = useStyles();
    return (
        <Show {...props} title={<DeviceShowTitle />}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="friendlyName" />
                <TextField source="address" />
                <TextField source="city" />
                <TextField source="deviceName" />
                <TextField
                    label="Service UUID"
                    source="serviceUUID"
                    fullWidth
                />
                <TextField source="writeCharacteristic" />
                <TextField source="readCharacteristic" />

                <ReferenceManyToManyField
                    source="id"
                    reference="users"
                    through="junction_user_device"
                    using="deviceUid,userUid"
                >
                    <Datagrid rowStyle={deviceRowStyle} stickyHeader>
                        <TextField
                            source="name"
                            headerClassName={classes.header}
                        />
                        <TextField
                            source="email"
                            headerClassName={classes.header}
                        />
                        <TextField
                            source="phoneNumber"
                            headerClassName={classes.header}
                        />
                    </Datagrid>
                </ReferenceManyToManyField>
            </SimpleShowLayout>
        </Show>
    );
};
