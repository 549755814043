import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";
// @ts-ignore
import defaultMuiTheme from "@material-ui/core/styles/defaultTheme";

const palette = createPalette(
    merge({}, defaultTheme.palette, {
        primary: {
            main: "#01305b",
        },
        secondary: {
            main: "#01305b",
        },
    })
);

const rawTheme = {
    palette,

    shape: {
        borderRadius: 0,
    },

    overrides: {
        RaSidebar: {
            drawerPaper: {
                backgroundColor: palette.common.white,
                color: palette.primary.main,
                height: "100%",
                boxShadow:
                    "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)",
            },
        },
        RaMenuItemLink: {
            active: {
                borderLeftStyle: "none",
                borderRightColor: palette.secondary.main,
                borderRightWidth: defaultMuiTheme.spacing(0.5),
                borderRightStyle: "solid",
                backgroundColor: palette.action.selected,
                color: palette.primary.main,
            },
            icon: {
                color: "inherit",
            },
        },
        RaLayout: {
            content: {
                height: "auto",
                backgroundColor: palette.background.default,
                paddingTop: defaultMuiTheme.spacing(0),
                paddingRight: defaultMuiTheme.spacing(0),
                paddingBottom: defaultMuiTheme.spacing(0),
                paddingLeft: defaultMuiTheme.spacing(0),
                display: "flex",
                flexDirection: "column",
                [defaultMuiTheme.breakpoints.up("xs")]: {
                    paddingTop: defaultMuiTheme.spacing(0),
                    paddingRight: defaultMuiTheme.spacing(0),
                    paddingBottom: defaultMuiTheme.spacing(0),
                    paddingLeft: defaultMuiTheme.spacing(0),
                },
                "& > div, & > h2": {
                    paddingTop: defaultMuiTheme.spacing(4),
                    paddingRight: defaultMuiTheme.spacing(3),
                    paddingBottom: defaultMuiTheme.spacing(3),
                    paddingLeft: defaultMuiTheme.spacing(3),
                    [defaultMuiTheme.breakpoints.up("xs")]: {
                        paddingLeft: defaultMuiTheme.spacing(6),
                    },
                },
            },
        },
        RaAppBar: {
            toolbar: {
                MuiIconButton: {
                    root: {
                        fontSize: "1.25rem",
                    },
                },
            },
        },
        RaShow: {
            main: {
                marginTop: defaultMuiTheme.spacing(2),
            },
            noActions: {
                marginTop: defaultMuiTheme.spacing(2),
            },
        },
        RaFilter: {
            form: {},
            button: {
                "& button": {
                    borderStyle: "solid",
                    borderWidth: "2px",
                    borderColor: palette.grey[300],
                    textTransform: "uppercase",
                    color: palette.primary.main,
                    paddingLeft: defaultMuiTheme.spacing(2),
                    paddingRight: defaultMuiTheme.spacing(2),
                    paddingTop: defaultMuiTheme.spacing(1),
                    paddingBottom: defaultMuiTheme.spacing(1),
                },
            },
        },

        MuiButton: {
            root: {
                color: palette.primary.main,
                paddingTop: defaultMuiTheme.spacing(1),
                paddingRight: defaultMuiTheme.spacing(4),
                paddingBottom: defaultMuiTheme.spacing(1),
                paddingLeft: defaultMuiTheme.spacing(4),
            },
            sizeSmall: {
                paddingTop: defaultMuiTheme.spacing(0),
                paddingRight: defaultMuiTheme.spacing(2),
                paddingBottom: defaultMuiTheme.spacing(0),
                paddingLeft: defaultMuiTheme.spacing(2),
            },
            sizeLarge: {
                paddingTop: defaultMuiTheme.spacing(2),
                paddingRight: defaultMuiTheme.spacing(6),
                paddingBottom: defaultMuiTheme.spacing(2),
                paddingLeft: defaultMuiTheme.spacing(6),
            },
            contained: {
                boxShadow: "none",
            },
            containedPrimary: {
                color: palette.common.white,
                backgroundColor: palette.primary.main,
            },
            containedSecondary: {
                color: palette.common.white,
                backgroundColor: palette.secondary.main,
            },
            outlined: {},
            outlinedPrimary: {
                color: palette.primary.main,
                borderColor: palette.primary.main,
            },
            outlinedSecondary: {
                color: palette.common.white,
                borderColor: palette.common.white,
            },
            text: {},
            textPrimary: {
                color: palette.primary.main,
            },
            textSecondary: {
                color: palette.secondary.main,
            },
            label: {},
        },

        RaListToolbar: {
            toolbar: {
                paddingBottom: defaultMuiTheme.spacing(1),
                borderBottomStyle: "solid",
                borderBottomWidth: "1px",
                borderBottomColor: palette.grey[300],
                marginBottom: defaultMuiTheme.spacing(2),
            },
            actions: {
                marginRight: "0px",
            },
        },
    },
};

export const theme = createMuiTheme(merge({}, defaultTheme, rawTheme));
