import * as React from "react";
import {
    TextInput,
    EditProps,
    Edit,
    SimpleForm,
    SelectArrayInput,
    Toolbar,
    SaveButton,
    useRedirect,
    useNotify,
    useRefresh,
} from "react-admin";
import {
    ManyToManyReferenceContextProvider,
    ReferenceManyToManyInput,
} from "ra-relationships";

const UserEditToolbar = (props: any) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
};

const UserEditTitle = ({ record }: any) => {
    return <span>Edit User: {record ? `${record.email}` : ""}</span>;
};

export const UserEdit = (props: EditProps) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = (response: any) => {
        notify(`User ${response.data.email} saved!`);
        redirect("/users");
        refresh();
    };
    return (
        <Edit {...props} title={<UserEditTitle />} onSuccess={onSuccess}>
            <ManyToManyReferenceContextProvider>
                <SimpleForm toolbar={<UserEditToolbar />}>
                    <TextInput disabled source="id" />
                    <TextInput source="name" />
                    <TextInput disabled source="email" />
                    <TextInput disabled source="phoneNumber" />
                    <ReferenceManyToManyInput
                        source="id"
                        reference="devices"
                        through="junction_user_device"
                        using="userUid,deviceUid"
                        fullWidth
                        label="Devices"
                    >
                        <SelectArrayInput
                            optionText={(choice: any) => {
                                return `${choice.friendlyName} - ${choice.address}, ${choice.city}`;
                            }}
                        />
                    </ReferenceManyToManyInput>
                </SimpleForm>
            </ManyToManyReferenceContextProvider>
        </Edit>
    );
};
