import * as React from "react";
import {
    TextInput,
    EditProps,
    Edit,
    SimpleForm,
    SelectArrayInput,
    Toolbar,
    SaveButton,
    useRedirect,
    useNotify,
    useRefresh,
    required,
} from "react-admin";
import {
    ManyToManyReferenceContextProvider,
    ReferenceManyToManyInput,
} from "ra-relationships";
import firebase from "firebase/app";
import "firebase/firestore";

const DeviceEditToolbar = (props: any) => {
    return (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );
};

const DeviceEditTitle = ({ record }: any) => {
    return <span>Edit Device: {record ? `${record.friendlyName}` : ""}</span>;
};

export const DeviceEdit = (props: EditProps) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const onSuccess = async (response: any) => {
        // Every junction that use the updated device should be notified
        const data = await firebase
            .firestore()
            .collection("junction_user_device")
            .where("deviceUid", "==", response.data.id)
            .get();
        data.forEach(async (doc) => {
            await firebase
                .firestore()
                .collection("junction_user_device")
                .doc(doc.id)
                .set(
                    { updatedAt: firebase.firestore.Timestamp.now() },
                    { merge: true }
                );
        });

        notify(`Device ${response.data.friendlyName} saved!`);
        refresh();
        redirect("/devices");
    };
    return (
        <Edit {...props} title={<DeviceEditTitle />} onSuccess={onSuccess}>
            <ManyToManyReferenceContextProvider>
                <SimpleForm toolbar={<DeviceEditToolbar />}>
                    <TextInput label="ID" disabled source="id" />
                    <TextInput
                        source="friendlyName"
                        validate={required()}
                        helperText="The name the client sees in the app"
                    />
                    <TextInput
                        source="address"
                        validate={required()}
                        helperText="The address which the device is in"
                    />
                    <TextInput
                        source="city"
                        validate={required()}
                        helperText="The city which the device is in"
                    />
                    <TextInput
                        source="deviceName"
                        validate={required()}
                        helperText="This is the name which the app uses to identify the device"
                        fullWidth
                    />
                    <TextInput
                        label="Service UUID"
                        source="serviceUUID"
                        fullWidth
                        helperText="The Bluetooth Service UUID for communication"
                        validate={required()}
                    />
                    <TextInput
                        source="writeCharacteristic"
                        fullWidth
                        helperText="The Bluetooth Characteristic UUID for write communication"
                        validate={required()}
                    />
                    <TextInput
                        source="readCharacteristic"
                        fullWidth
                        helperText="The Bluetooth Characteristic UUID for read communication"
                        validate={required()}
                    />
                    <ReferenceManyToManyInput
                        source="id"
                        reference="users"
                        through="junction_user_device"
                        using="deviceUid,userUid"
                        fullWidth
                        label="Users"
                    >
                        <SelectArrayInput
                            optionText={(choice: any) => {
                                return `${choice.name} - ${choice.email} - ${choice.phoneNumber}`;
                            }}
                            helperText="Users assigned to current device"
                        />
                    </ReferenceManyToManyInput>
                </SimpleForm>
            </ManyToManyReferenceContextProvider>
        </Edit>
    );
};
