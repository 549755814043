/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    ListProps,
    ShowButton,
    EditButton,
    TopToolbar,
    ExportButton,
} from "react-admin";

const UserFilter = (props: any) => {
    return (
        <Filter {...props}>
            <TextInput label="Name" source="name" alwaysOn />
            <TextInput label="Email" source="email" alwaysOn />
            <TextInput label="Phone number" source="phoneNumber" alwaysOn />
        </Filter>
    );
};

const ListActions = (props: any) => (
    <TopToolbar>
        {React.cloneElement(props.filters, { context: "button" })}
        <ExportButton />
    </TopToolbar>
);

export const UserList = (props: ListProps) => {
    return (
        <List
            {...props}
            filters={<UserFilter />}
            bulkActionButtons={<></>}
            perPage={25}
            actions={<ListActions />}
        >
            <Datagrid rowClick="edit">
                <TextField source="name" />
                <TextField source="email" />
                <TextField source="phoneNumber" />
                <ShowButton />
                <EditButton basePath="/users" />
            </Datagrid>
        </List>
    );
};
