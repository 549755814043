import React from "react";
import "./App.css";
import { Admin, Resource } from "react-admin";
import UserIcon from "@material-ui/icons/Group";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import {
    FirebaseAuthProvider,
    FirebaseDataProvider,
    RAFirebaseOptions,
} from "react-admin-firebase";
import firebase from "firebase/app";
import { UserList, UserEdit, UserShow } from "./users";
import Dashboard from "./Dashboard";
import { DeviceCreate, DeviceEdit, DeviceShow, DevicesList } from "./devices";
import CustomLoginPage from "./login/Login";
import { theme } from "./theme";

// eslint-disable-next-line import/extensions
const config = require("./config/firebase.js").firebaseConfig;

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const options: RAFirebaseOptions = {
    logging: false,
    watch: ["users", "devices", "junction_device_users"],
    renameMetaFields: {
        created_at: "createdAt",
        created_by: "createdBy",
        updated_at: "updatedAt",
        updated_by: "updatedBy",
    },
    associateUsersById: true,
};

const dataProvider2 = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

function App() {
    return (
        <Admin
            theme={theme}
            authProvider={authProvider}
            dataProvider={dataProvider2}
            loginPage={CustomLoginPage}
            dashboard={Dashboard}
            disableTelemetry
        >
            <Resource
                name="users"
                icon={UserIcon}
                list={UserList}
                show={UserShow}
                edit={UserEdit}
            />
            <Resource
                name="devices"
                icon={DeviceHubIcon}
                list={DevicesList}
                show={DeviceShow}
                edit={DeviceEdit}
                create={DeviceCreate}
            />
            <Resource name="junction_user_device" />
        </Admin>
    );
}

export default App;
