/* eslint-disable unused-imports/no-unused-vars-ts */
/* eslint-disable react/require-default-props */
import * as React from "react";
import { FC, Fragment, useState, ReactElement } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import PropTypes from "prop-types";
import ActionDelete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import inflection from "inflection";
import { makeStyles } from "@material-ui/core/styles";
import {
    useTranslate,
    useDeleteMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    CRUD_DELETE_MANY,
    useResourceContext,
} from "ra-core";

import {
    Confirm,
    Button,
    ButtonProps,
    BulkActionProps,
    useDataProvider,
} from "react-admin";

const useStyles = makeStyles(
    (theme) => ({
        deleteButton: {
            color: theme.palette.error.main,
            "&:hover": {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                "@media (hover: none)": {
                    backgroundColor: "transparent",
                },
            },
        },
    }),
    { name: "RaDevicesBulkDeleteWithConfirmationButton" }
);

const defaultIcon = <ActionDelete />;

const DevicesBulkDeleteWithConfirmationButton: FC<DevicesBulkDeleteWithConfirmationButtonProps> =
    (props) => {
        const {
            basePath,
            classes: classesOverride,
            confirmTitle = "ra.message.bulk_delete_title",
            confirmContent = "ra.message.bulk_delete_content",
            icon = defaultIcon,
            label,
            onClick,
            selectedIds,
            ...rest
        } = props;
        const [isOpen, setOpen] = useState(false);
        const classes = useStyles(props);
        const notify = useNotify();
        const unselectAll = useUnselectAll();
        const refresh = useRefresh();
        const translate = useTranslate();
        const resource = useResourceContext(props);
        const dataProvider = useDataProvider();
        const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
            action: CRUD_DELETE_MANY,
            onSuccess: async () => {
                dataProvider.deleteMany("junction_user_device", {
                    ids: selectedIds!,
                });

                // Custom logic to delete the relationships of a device when a device is removed.
                // The reason we need this is because the many to many reference deletion is not working seamlessly for the bulk deletion
                selectedIds!.forEach(async (value: any, _a: any, _b: any) => {
                    const data = await firebase
                        .firestore()
                        .collection("junction_user_device")
                        .where("deviceUid", "==", value)
                        .get();
                    data.forEach(async (doc) => {
                        await firebase
                            .firestore()
                            .collection("junction_user_device")
                            .doc(doc.id)
                            .delete();
                    });
                });

                refresh();
                notify("ra.notification.deleted", "info", {
                    smart_count: selectedIds!.length,
                });
                unselectAll(resource);
            },
            onFailure: (error) => {
                notify(
                    typeof error === "string"
                        ? error
                        : error.message || "ra.notification.http_error",
                    "warning",
                    {
                        _:
                            // eslint-disable-next-line no-nested-ternary
                            typeof error === "string"
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    }
                );
                setOpen(false);
            },
        });

        const handleClick = (e: any) => {
            setOpen(true);
            e.stopPropagation();
        };

        const handleDialogClose = () => {
            setOpen(false);
        };

        const handleDelete = (e: any) => {
            deleteMany();

            if (typeof onClick === "function") {
                onClick(e);
            }
        };

        return (
            <>
                <Button
                    onClick={handleClick}
                    label={label}
                    className={classes.deleteButton}
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    {...sanitizeRestProps(rest)}
                >
                    {icon}
                </Button>
                <Confirm
                    isOpen={isOpen}
                    loading={loading}
                    title={confirmTitle}
                    content={confirmContent}
                    translateOptions={{
                        smart_count: selectedIds!.length,
                        name: translate(
                            `resources.${resource}.forcedCaseName`,
                            {
                                smart_count: selectedIds!.length,
                                _: inflection.humanize(
                                    translate(`resources.${resource}.name`, {
                                        smart_count: selectedIds!.length,
                                        _: inflection.inflect(
                                            resource,
                                            selectedIds!.length
                                        ),
                                    }),
                                    true
                                ),
                            }
                        ),
                    }}
                    onConfirm={handleDelete}
                    onClose={handleDialogClose}
                />
            </>
        );
    };

const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    label,
    ...rest
}: Omit<
    DevicesBulkDeleteWithConfirmationButtonProps,
    "resource" | "selectedIds" | "icon"
>) => rest;

export interface DevicesBulkDeleteWithConfirmationButtonProps
    extends BulkActionProps,
        ButtonProps {
    confirmContent?: React.ReactNode;
    confirmTitle?: string;
    icon?: ReactElement;
}

DevicesBulkDeleteWithConfirmationButton.propTypes = {
    basePath: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    classes: PropTypes.object,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    label: PropTypes.string,
    resource: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    icon: PropTypes.element,
};

DevicesBulkDeleteWithConfirmationButton.defaultProps = {
    label: "ra.action.delete",
};

export default DevicesBulkDeleteWithConfirmationButton;
